import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Users, CreditCard, Film, Folder, Layout, MessageCircle } from 'lucide-react';
import api from '../../services/api';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface DashboardStats {
  totalUsers: number;
  activeSubscriptions: number;
  totalVideos: number;
  totalExerciseSets: number;
  totalPrograms: number;
  newMessages: number;
}

const AdminDashboard: React.FC = () => {
  const [stats, setStats] = useState<DashboardStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    const fetchDashboardStats = async () => {
      try {
        const response = await api.get<DashboardStats>('/api/admin/dashboard-stats');
        setStats(response.data);
      } catch (err) {
        console.error('Error fetching dashboard stats:', err);
        setError('Failed to load dashboard statistics');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardStats();
  }, []);

  if (loading) return <div className="p-6">Loading dashboard data...</div>;
  if (error) return <div className="p-6 text-red-500">{error}</div>;

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-6">Welcome, {user?.first_name}</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <DashboardCard
          title="Users"
          description="Manage user accounts and permissions"
          link="/users"
          icon={<Users size={48} />}
          stat={stats?.totalUsers}
        />
        <DashboardCard
          title="Subscriptions"
          description="View and manage user subscriptions"
          link="/subscriptions"
          icon={<CreditCard size={48} />}
          stat={stats?.activeSubscriptions}
        />
        <DashboardCard
          title="Videos"
          description="Manage training videos"
          link="/videos"
          icon={<Film size={48} />}
          stat={stats?.totalVideos}
        />
        <DashboardCard
          title="Exercise Sets"
          description="Create and manage exercise sets"
          link="/exercise-sets"
          icon={<Folder size={48} />}
          stat={stats?.totalExerciseSets}
        />
        <DashboardCard
          title="Programs"
          description="Design and manage training programs"
          link="/programs"
          icon={<Layout size={48} />}
          stat={stats?.totalPrograms}
        />
        <DashboardCard
          title="Messages"
          description="View and respond to user messages"
          link="/messages"
          icon={<MessageCircle size={48} />}
          stat={stats?.newMessages}
          statLabel="new"
        />
      </div>
    </div>
  );
};

interface DashboardCardProps {
  title: string;
  description: string;
  link: string;
  icon: React.ReactNode;
  stat?: number;
  statLabel?: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ({ title, description, link, icon, stat, statLabel = "total" }) => {
  return (
    <Link to={link} className="block p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow">
      <div className="flex items-center mb-4">
        <div className="mr-4 text-blue-500">
          {icon}
        </div>
        <h2 className="text-xl font-semibold">{title}</h2>
      </div>
      <p className="text-gray-600 mb-4">{description}</p>
      {stat !== undefined && (
        <div className="text-3xl text-blue-600">
          {stat} <span className="text-sm text-gray-500">{statLabel}</span>
        </div>
      )}
    </Link>
  );
};

export default AdminDashboard;