import React, { useState } from 'react';
import { UserInstructorMessage, HelpSupportMessage } from '../../store/messageSlice';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Input } from '@/components/ui/input';

interface User {
    id: number;
    first_name: string;
    last_name: string;
    messageCount: number;
    hasUnreadMessages: boolean;
  }
  
  interface UserListProps {
    users: User[];
    selectedUserId: number | null;
    onUserSelect: (userId: number) => void;
    messages: (UserInstructorMessage | HelpSupportMessage)[];
  }

const UserList: React.FC<UserListProps> = ({ users, selectedUserId, onUserSelect, messages }) => {
    const usersWithMessageCounts = users.map(user => ({
        ...user,
        messageCount: messages.filter(m => m.user_id === user.id).length,
        hasUnreadMessages: messages.some(m => m.user_id === user.id && !m.read && m.sender_role === 'user')
      }));
      
  const [searchQuery, setSearchQuery] = useState('');

  const filteredUsers = users.filter(user =>
    `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Card className="h-full flex flex-col">
      <CardHeader className="pb-2">
        <CardTitle>User List</CardTitle>
      </CardHeader>
      <CardContent className="flex-grow flex flex-col p-2">
        <Input
          className="mb-2"
          placeholder="Search users..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="overflow-y-auto flex-grow">
          {filteredUsers.length > 0 ? (
            <ul className="space-y-1 text-sm">
              {filteredUsers.map((user) => (
                <li 
                  key={user.id} 
                  className={`cursor-pointer p-2 rounded flex justify-between items-center ${
                    selectedUserId === user.id ? 'bg-blue-100' : ''
                  }`}
                  onClick={() => onUserSelect(user.id)}
                >
                  <span className={user.hasUnreadMessages ? 'font-bold' : ''}>
                    {user.first_name} {user.last_name}
                  </span>
                  {user.messageCount > 0 && (
                    <Badge variant="secondary">{user.messageCount}</Badge>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p>No users found</p>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default UserList;