import axios from 'axios';
import { store } from '../store/store';
import { logout, refreshToken } from '../store/authSlice';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3000',
});

// Function to handle admin-specific routes
const adminRequest = (method: string, url: string, data?: any) => {
  //console.log('Admin request:', { method, url, data });
  return api({
    method,
    url: `/api/admin${url}`,
    data
  });
};

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  
  failedQueue = [];
};

api.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.token;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return api(originalRequest);
        }).catch(err => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        await store.dispatch(refreshToken()).unwrap();
        const newToken = store.getState().auth.token;
        if (newToken) {
          processQueue(null, newToken);
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return api(originalRequest);
        } else {
          processQueue(new Error('Failed to refresh token'));
          store.dispatch(logout());
          throw new Error('Session expired. Please log in again.');
        }
      } catch (refreshError) {
        processQueue(refreshError);
        store.dispatch(logout());
        throw refreshError;
      } finally {
        isRefreshing = false;
      }
    }
    return Promise.reject(error);
  }
);

export default api;

// Export the adminRequest function for admin-specific API calls
export { adminRequest };