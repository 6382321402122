import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { adminRequest } from '../services/api';
import axios from 'axios';

// Common properties for both message types
interface BaseMessage {
  id: number;
  user_id: number;
  sender_role: 'user' | 'admin';
  message: string;
  read: boolean;
  created_at: string;
  updated_at: string;
  user_username: string;
}

// User-Instructor message
export interface UserInstructorMessage extends BaseMessage {
  instructor_id: number;
  admin_username: string;
  message_type: 'user_instructor';
}

// Help & Support message
export interface HelpSupportMessage extends BaseMessage {
  admin_id: number | null;
  admin_username: string | null;
  subject: string;
  message_type: 'help_support';
  status: 'open' | 'in_progress' | 'closed';
  thread_id: number;
  thread_title: string;
}

// Union type for general use
export type Message = UserInstructorMessage | HelpSupportMessage;

export interface AdminMessagesState {
  userInstructorMessages: UserInstructorMessage[];
  helpSupportMessages: HelpSupportMessage[];
  users: User[];
  isLoading: boolean;
  error: string | null;
}

const initialState: AdminMessagesState = {
  userInstructorMessages: [],
  helpSupportMessages: [],
  users: [],
  isLoading: false,
  error: null,
};

interface User {
  id: number;
  first_name: string;
  last_name: string;
}

export const fetchAdminMessages = createAsyncThunk<UserInstructorMessage[], void, { rejectValue: string }>(
  'adminMessages/fetchMessages',
  async (_, { rejectWithValue }) => {
    try {
      const response = await adminRequest('get', '/messages');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch messages');
    }
  }
);

export const fetchHelpSupportMessages = createAsyncThunk<HelpSupportMessage[], void, { rejectValue: string }>(
  'adminMessages/fetchHelpSupportMessages',
  async (_, { rejectWithValue }) => {
    try {
      const response = await adminRequest('get', '/messages/help-support');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch help & support messages');
    }
  }
);

export const fetchUserMessages = createAsyncThunk<UserInstructorMessage[], number, { rejectValue: string }>(
  'adminMessages/fetchUserMessages',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await adminRequest('get', `/messages/user/${userId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch user messages');
    }
  }
);

export const sendAdminMessage = createAsyncThunk<UserInstructorMessage, { userId: number; message: string }, { rejectValue: string }>(
  'adminMessages/sendMessage',
  async ({ userId, message }, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminRequest('post', '/messages', { userId, message });
      // Fetch updated messages after sending a new message
      dispatch(fetchUserMessages(userId));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to send message');
    }
  }
);

export const markMessageAsRead = createAsyncThunk<
  { id: number, messageType: 'instructor' | 'help_support' },
  { messageId: number, messageType: 'instructor' | 'help_support' },
  { rejectValue: string }
>(
  'adminMessages/markAsRead',
  async ({ messageId, messageType }, { rejectWithValue }) => {
    try {
      await adminRequest('put', '/messages/read', { messageId, messageType });
      return { id: messageId, messageType };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to mark message as read');
    }
  }
);

export const sendHelpSupportReply = createAsyncThunk<HelpSupportMessage, { messageId: number, reply: string }, { rejectValue: string }>(
  'adminMessages/sendHelpSupportReply',
  async ({ messageId, reply }, { rejectWithValue }) => {
    try {
      const response = await adminRequest('post', `/messages/help-support/${messageId}/reply`, { reply });
      
      // Ensure the response data matches our HelpSupportMessage interface
      const sentMessage: HelpSupportMessage = {
        id: response.data.id,
        user_id: response.data.user_id,
        admin_id: response.data.admin_id,
        sender_role: 'admin',
        subject: response.data.subject,
        message: response.data.message,
        read: false,
        created_at: response.data.created_at,
        updated_at: response.data.updated_at,
        user_username: response.data.user_username,
        admin_username: response.data.admin_username,
        message_type: 'help_support',
        status: response.data.status || 'open',
        thread_id: response.data.thread_id,
        thread_title: response.data.thread_subject,
      };

      return sentMessage;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to send help & support reply');
    }
  }
);

export const fetchUsers = createAsyncThunk<User[], void, { rejectValue: string }>(
  'adminMessages/fetchUsers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await adminRequest('get', '/users');
      return response.data.users.map((user: any) => ({
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name
      }));
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch users');
    }
  }
);

export const resolveHelpSupportThread = createAsyncThunk(
  'messages/resolveHelpSupportThread',
  async (threadId: number, { rejectWithValue }) => {
    try {
      //console.log('Sending resolve request for thread:', threadId);
      const response = await adminRequest('put', `/messages/help-support/${threadId}/resolve`);
      //console.log('Resolve request successful:', response.data);
      return response.data;
    } catch (error) {
      //console.error('Error in resolveHelpSupportThread:', error);
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data || 'An error occurred');
      } else {
        console.error('Non-Axios error:', error);
        return rejectWithValue('An unexpected error occurred');
      }
    }
  }
);

const messageSlice = createSlice({
  name: 'adminMessages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminMessages.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAdminMessages.fulfilled, (state, action: PayloadAction<UserInstructorMessage[]>) => {
        state.isLoading = false;
        state.userInstructorMessages = action.payload;
      })
      .addCase(fetchAdminMessages.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'An error occurred';
      })
      .addCase(fetchHelpSupportMessages.fulfilled, (state, action: PayloadAction<HelpSupportMessage[]>) => {
        state.helpSupportMessages = action.payload;
      })
      .addCase(fetchUserMessages.fulfilled, (state, action: PayloadAction<UserInstructorMessage[]>) => {
        state.userInstructorMessages = action.payload;
      })
      .addCase(sendAdminMessage.fulfilled, (state, action: PayloadAction<UserInstructorMessage>) => {
        // We don't need to update state here as fetchUserMessages will update it
      })
      .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
        state.users = action.payload;
      })
      .addCase(markMessageAsRead.fulfilled, (state, action: PayloadAction<{ id: number, messageType: 'instructor' | 'help_support' }>) => {
        if (action.payload.messageType === 'instructor') {
          const message = state.userInstructorMessages.find(m => m.id === action.payload.id);
          if (message) {
            message.read = true;
          }
        } else {
          const message = state.helpSupportMessages.find(m => m.id === action.payload.id);
          if (message) {
            message.read = true;
          }
        }
      })
      .addCase(sendHelpSupportReply.fulfilled, (state, action: PayloadAction<HelpSupportMessage>) => {
        const threadIndex = state.helpSupportMessages.findIndex(m => m.thread_id === action.payload.thread_id);
        if (threadIndex !== -1) {
          // Find the correct position to insert the new message
          const insertIndex = state.helpSupportMessages.findIndex(m => 
            m.thread_id === action.payload.thread_id && 
            new Date(m.created_at) > new Date(action.payload.created_at)
          );
          
          if (insertIndex === -1) {
            // If no message with a later date was found, append to the end
            state.helpSupportMessages.push(action.payload);
          } else {
            // Insert the new message at the correct position
            state.helpSupportMessages.splice(insertIndex, 0, action.payload);
          }
        } else {
          // If it's a new thread, add it to the end
          state.helpSupportMessages.push(action.payload);
        }
      })
      .addCase(resolveHelpSupportThread.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resolveHelpSupportThread.fulfilled, (state, action) => {
        state.isLoading = false;
        state.helpSupportMessages = state.helpSupportMessages.map(message => 
          message.thread_id === action.payload[0].thread_id 
            ? { ...message, status: 'closed' } 
            : message
        );
      })
      .addCase(resolveHelpSupportThread.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export default messageSlice.reducer;