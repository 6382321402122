import React, { useState } from 'react';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import logo from '../../body-harmony-logo.png';
import { Menu, X, LogOut } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/authSlice';

const Layout: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const menuItems = [
    { path: '/', label: 'Dashboard' },
    { path: '/exercise-sets', label: 'Sets of Exercises' },
    { path: '/programs', label: 'Programs' },
    { path: '/videos', label: 'Manage Videos' },
    { path: '/users', label: 'Manage Users' },
    { path: '/subscriptions', label: 'Subscriptions' },
    { path: '/messages', label: 'Messages' },
    { path: '/help-support', label: 'Help & Support' },
  ];

  return (
    <div className="flex h-screen bg-gray-100">
      <button
        className="lg:hidden fixed top-4 left-4 z-20 p-2 bg-white rounded shadow"
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      <aside className={`
        ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
        lg:translate-x-0
        fixed lg:static inset-y-0 left-0 z-10
        w-64 bg-white shadow-md transition-transform duration-300 ease-in-out
        flex flex-col
      `}>
        <div className="p-4 lg:p-6">
          <img className="mx-auto h-16 lg:h-24 w-auto" src={logo} alt="Body Harmony Logo" />
        </div>
        <nav className="mt-2 flex-grow">
          {menuItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`block py-2 px-4 text-sm lg:text-base ${
                location.pathname === item.path ? 'bg-gray-200 text-gray-900' : 'text-gray-600 hover:bg-gray-100'
              }`}
              onClick={() => setIsSidebarOpen(false)}
            >
              {item.label}
            </Link>
          ))}
        </nav>
        <button
          onClick={handleLogout}
          className="flex items-center py-2 px-4 text-sm lg:text-base text-gray-600 hover:bg-gray-100"
        >
          <LogOut size={20} className="mr-2" />
          Logout
        </button>
      </aside>

      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200 pb-0"> {/* Added pb-16 for footer space */}
          <div className="container mx-auto px-1 lg:px-2 py-2 lg:py-2">
            <Outlet />
          </div>
        </main>
      </div>

      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-5 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
    </div>
  );
};

export default Layout;