import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../services/api'
import axios from 'axios';;

export interface ExerciseSet {
  id: number;
  title: string;
  description: string;
  programs: string[];
  videoIds: number[];
}

interface ExerciseSetState {
  exerciseSets: ExerciseSet[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: ExerciseSetState = {
  exerciseSets: [],
  status: 'idle',
  error: null,
};

export const fetchExerciseSets = createAsyncThunk(
  'exerciseSets/fetchExerciseSets',
  async () => {
    const response = await api.get('/api/exercise-sets');
    return response.data;
  }
);

export const deleteExerciseSet = createAsyncThunk(
  'exerciseSets/deleteExerciseSet', 
  async (id: number, { rejectWithValue }) => {
    try {
      await api.delete(`/api/exercise-sets/${id}`);
      return id;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Error deleting exercise set:', error.response?.data || error.message);
        return rejectWithValue(error.response?.data || 'An error occurred while deleting the exercise set');
      }
      console.error('Unexpected error:', error);
      return rejectWithValue('An unexpected error occurred');
    }
  }
);

export const updateExerciseSet = createAsyncThunk(
  'exerciseSets/updateExerciseSet',
  async (updatedSet: ExerciseSet, { rejectWithValue }) => {
    try {
      const response = await api.put(`/api/exercise-sets/${updatedSet.id}`, updatedSet);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Error updating exercise set:', error.response?.data || error.message);
        return rejectWithValue(error.response?.data || 'An error occurred while updating the exercise set');
      }
      console.error('Unexpected error:', error);
      return rejectWithValue('An unexpected error occurred');
    }
  }
);

const exerciseSetSlice = createSlice({
  name: 'exerciseSets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExerciseSets.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchExerciseSets.fulfilled, (state, action: PayloadAction<ExerciseSet[]>) => {
        state.status = 'succeeded';
        state.exerciseSets = action.payload;
      })
      .addCase(fetchExerciseSets.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'An unknown error occurred';
      })
      .addCase(deleteExerciseSet.fulfilled, (state, action: PayloadAction<number>) => {
        state.exerciseSets = state.exerciseSets.filter(set => set.id !== action.payload);
      })
      .addCase(updateExerciseSet.fulfilled, (state, action: PayloadAction<ExerciseSet>) => {
        const index = state.exerciseSets.findIndex(set => set.id === action.payload.id);
        if (index !== -1) {
          state.exerciseSets[index] = action.payload;
        }
      });
  },
});

export default exerciseSetSlice.reducer;