import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from './store/store';
import LoginPage from './pages/LoginPage';
import AdminDashboard from './components/dashboard/AdminDashboard';
import ManageVideos from './components/videos/ManageVideos';
import ManageUsers from './components/users/ManageUsers';
import ManageSubscriptions from './components/subscriptions/ManageSubscriptions';
import ManageExerciseSets from './components/exerciseSets/ManageExerciseSets';
import ManagePrograms from './components/programs/ManagePrograms';
import AdminMessages from './components/messages/AdminMessages';
import AdminHelpSupport from './components/messages/AdminHelpSupport';
import Layout from './components/layout/Layout';
import ProtectedRoute from './components/ProtectedRoute';

const AppContent: React.FC = () => {
  const { user, token } = useSelector((state: RootState) => state.auth);



  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<ProtectedRoute />}>
          <Route element={<Layout />}>
            <Route index element={<AdminDashboard />} />
            <Route path="/videos" element={<ManageVideos />} />
            <Route path="/exercise-sets" element={<ManageExerciseSets />} />
            <Route path="/programs" element={<ManagePrograms />} />
            <Route path="/users" element={<ManageUsers />} />
            <Route path="/subscriptions" element={<ManageSubscriptions />} />
            <Route path="/messages" element={<AdminMessages />} />
            <Route path="/help-support" element={<AdminHelpSupport />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

const App: React.FC = () => {
  return (
    <>
      <ToastContainer />
      <AppContent />
    </>
  );
};

export default App;