import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Calendar } from "@/components/ui/calendar"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { addWeeks, format, parse, parseISO, isAfter, isBefore, isToday } from 'date-fns'
import { CalendarIcon } from "lucide-react"
import { cn } from "@/lib/utils"
import { Program, ExerciseSet, SubscriptionWithUser } from '../users/interfaces';


interface EditSubscriptionModalProps {
  subscription: SubscriptionWithUser;
  programs: Program[];
  allExerciseSets: ExerciseSet[];
  subscriptionExerciseSets: ExerciseSet[];
  isOpen: boolean;
  onClose: () => void;
  onSave: (updatedSubscription: SubscriptionWithUser, newProgram?: Program) => void;
}

const EditSubscriptionModal: React.FC<EditSubscriptionModalProps> = ({
  subscription,
  programs,
  allExerciseSets,
  subscriptionExerciseSets,
  isOpen,
  onClose,
  onSave
}) => {
  const [updatedSubscription, setUpdatedSubscription] = useState<SubscriptionWithUser>(subscription);
  const [subscriptionStatus, setSubscriptionStatus] = useState<'pending' | 'active' | 'finished'>('active');
  const [weeklyExerciseSets, setWeeklyExerciseSets] = useState<ExerciseSet[]>([]);
  const [isModified, setIsModified] = useState(false);
  const [newProgramName, setNewProgramName] = useState('');
  const [newProgramDescription, setNewProgramDescription] = useState('');

  useEffect(() => {
    //console.log('Subscription in modal:', subscription);
    //console.log('All exercise sets:', allExerciseSets);
    //console.log('Subscription exercise sets:', subscriptionExerciseSets);
    setUpdatedSubscription(subscription);
    setSubscriptionStatus(getSubscriptionStatus(subscription));
    initializeWeeklyExerciseSets();
    setIsModified(false);
    setNewProgramName('');
    setNewProgramDescription('');
  }, [subscription, allExerciseSets, subscriptionExerciseSets]);

  const initializeWeeklyExerciseSets = () => {
    const initialSets = Array(subscription.durationWeeks).fill(null).map((_, index) => {
      return subscriptionExerciseSets[index] || allExerciseSets[0] || { id: '', title: 'No exercise set available' };
    });
    //console.log('Initialized weekly exercise sets:', initialSets);
    setWeeklyExerciseSets(initialSets);
  };

  const handleExerciseSetChange = (weekIndex: number, setId: string) => {
    const newSets = [...weeklyExerciseSets];
    const selectedSet = allExerciseSets.find(set => set.id === setId) || { id: setId, title: 'Unknown Set' };
    newSets[weekIndex] = selectedSet;
    setWeeklyExerciseSets(newSets);
    setIsModified(true);
  };

  const getSubscriptionStatus = (sub: SubscriptionWithUser): 'pending' | 'active' | 'finished' => {
    const today = new Date();
    const startDate = parseISO(sub.startDate);
    const endDate = parseISO(sub.endDate);
    if (today > endDate) return 'finished';
    if (today < startDate) return 'pending';
    return 'active';
  };

  const handleDateChange = (field: 'startDate' | 'endDate', date: Date | undefined) => {
    if (date) {
      setUpdatedSubscription(prev => ({ ...prev, [field]: format(date, 'yyyy-MM-dd') }));
      setIsModified(true);
    }
  };

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const duration = parseInt(e.target.value);
    if (!isNaN(duration)) {
      const newEndDate = format(addWeeks(parseISO(updatedSubscription.startDate), duration), 'yyyy-MM-dd');
      setUpdatedSubscription(prev => ({
        ...prev,
        durationWeeks: duration,
        endDate: newEndDate
      }));
      setWeeklyExerciseSets(prev => {
        const newSets = [...prev];
        while (newSets.length < duration) {
          newSets.push(allExerciseSets[0] || { id: '', title: 'No exercise set available' });
        }
        return newSets.slice(0, duration);
      });
      setIsModified(true);
    }
  };

  const handleSave = () => {
    if (isModified) {
      const newProgram: Program = {
        id: `new-${Date.now()}`, // Temporary ID, should be replaced by the backend
        title: newProgramName,
        description: newProgramDescription,
        instructor_id: subscription.userId,
        created_at: new Date(),
        updated_at: new Date()
      };
      onSave({
        ...updatedSubscription,
        programId: newProgram.id,
        programTitle: newProgram.title,
        exerciseSets: weeklyExerciseSets,
        status: updatedSubscription.status // Ensure status is included
      }, newProgram);
    } else {
      onSave(updatedSubscription);
    }
  };

  const renderFormFields = () => {
    //console.log('Rendering form fields. Weekly exercise sets:', weeklyExerciseSets);
    
    switch (subscriptionStatus) {
      case 'pending':
      case 'active':
        return (
          <>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label className="text-right">Program</Label>
              <span className="col-span-3">{updatedSubscription.programTitle}</span>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label className="text-right">Start Date</Label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className={cn(
                      "w-[240px] justify-start text-left font-normal",
                      !updatedSubscription.startDate && "text-muted-foreground"
                    )}
                    disabled={subscriptionStatus === 'active'}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {updatedSubscription.startDate ? format(parseISO(updatedSubscription.startDate), 'dd-MM-yyyy') : <span>Pick a date</span>}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={parseISO(updatedSubscription.startDate)}
                    onSelect={(date) => handleDateChange('startDate', date)}
                    disabled={subscriptionStatus === 'active'}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="durationWeeks" className="text-right">Duration (weeks)</Label>
              <Input
                id="durationWeeks"
                type="number"
                value={updatedSubscription.durationWeeks}
                onChange={handleDurationChange}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label className="text-right">End Date</Label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className={cn(
                      "w-[240px] justify-start text-left font-normal",
                      !updatedSubscription.endDate && "text-muted-foreground"
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {updatedSubscription.endDate ? format(parseISO(updatedSubscription.endDate), 'dd-MM-yyyy') : <span>Pick a date</span>}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={parseISO(updatedSubscription.endDate)}
                    onSelect={(date) => handleDateChange('endDate', date)}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>

            <div className="mt-4">
              <Label>Weekly Exercise Sets</Label>
              {weeklyExerciseSets.map((set, index) => (
                <div key={index} className="flex items-center mt-2">
                  <span className="mr-2">Week {index + 1}:</span>
                  <Select 
                    onValueChange={(value) => handleExerciseSetChange(index, value)} 
                    value={set.id}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue>{set.title}</SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      {allExerciseSets.map((exerciseSet) => (
                        <SelectItem key={exerciseSet.id} value={exerciseSet.id}>
                          {exerciseSet.title}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              ))}
            </div>

            {isModified && (
              <div className="mt-4">
                <Label>New Program Details</Label>
                <Input
                  type="text"
                  placeholder="New Program Name"
                  value={newProgramName}
                  onChange={(e) => setNewProgramName(e.target.value)}
                  className="mt-2"
                />
                <Input
                  type="text"
                  placeholder="New Program Description"
                  value={newProgramDescription}
                  onChange={(e) => setNewProgramDescription(e.target.value)}
                  className="mt-2"
                />
              </div>
            )}
          </>
        );
      case 'finished':
        return (
          <>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label className="text-right">Program</Label>
              <span className="col-span-3">{updatedSubscription.programTitle}</span>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label className="text-right">Start Date</Label>
              <span className="col-span-3">{format(parseISO(updatedSubscription.startDate), 'dd-MM-yyyy')}</span>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label className="text-right">End Date</Label>
              <span className="col-span-3">{format(parseISO(updatedSubscription.endDate), 'dd-MM-yyyy')}</span>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label className="text-right">Duration (weeks)</Label>
              <span className="col-span-3">{updatedSubscription.durationWeeks}</span>
            </div>
            <div className="mt-4">
              <Label>Weekly Exercise Sets</Label>
              {weeklyExerciseSets.map((set, index) => (
                <div key={index} className="mt-2">
                  <span>Week {index + 1}: {set.title}</span>
                </div>
              ))}
            </div>
          </>
        );
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle>
            {subscriptionStatus === 'finished' ? 'Subscription Details' : 'Edit Subscription'}
          </DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4 max-h-[60vh] overflow-y-auto">
          {renderFormFields()}
        </div>
        <DialogFooter>
          <Button type="button" variant="outline" onClick={onClose}>
            Close
          </Button>
          {subscriptionStatus !== 'finished' && (
            <Button type="button" onClick={handleSave} disabled={isModified && (!newProgramName || !newProgramDescription)}>
              Save changes
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};


export default EditSubscriptionModal;