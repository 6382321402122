import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPrograms, deleteProgram, updateProgram, Program } from '../../store/programSlice';
import { RootState, AppDispatch } from '../../store/store';
import { toast } from 'react-toastify';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Edit, Trash2 } from 'lucide-react';
import EditProgramModal from './EditProgramModal'; // You'll need to create this component

const ManagePrograms: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { programs, status, error } = useSelector((state: RootState) => state.programs);
  const [selectedProgram, setSelectedProgram] = useState<Program | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    dispatch(fetchPrograms());
  }, [dispatch]);

  const handleSelectProgram = (program: Program) => {
    setSelectedProgram(program.id === selectedProgram?.id ? null : program);
  };

  const handleEditProgram = () => {
    if (selectedProgram) {
      // Fetch the most up-to-date program data before opening the modal
      const currentProgram = programs.find(p => p.id === selectedProgram.id);
      if (currentProgram) {
        setSelectedProgram(currentProgram);
        setIsEditModalOpen(true);
      }
    }
  };

  const handleDeleteProgram = async () => {
    if (selectedProgram && window.confirm('Are you sure you want to delete this program?')) {
      try {
        await dispatch(deleteProgram(selectedProgram.id)).unwrap();
        toast.success('Program deleted successfully');
        setSelectedProgram(null);
      } catch (error) {
        toast.error('Failed to delete program');
      }
    }
  };

  const handleSaveProgram = async (updatedProgram: Program) => {
    try {
      await dispatch(updateProgram(updatedProgram)).unwrap();
      toast.success('Program updated successfully');
      setIsEditModalOpen(false);
    } catch (error) {
      toast.error('Failed to update program');
    }
  };

  const filteredPrograms = programs.filter(program =>
    program.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    program.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-auto pb-16">
        {/* Table for larger screens */}
        <table className="w-full hidden md:table">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 text-left">Program</th>
              <th className="p-2 text-left">Description</th>
              <th className="p-2 text-left">Exercise Sets</th>
            </tr>
          </thead>
          <tbody>
            {filteredPrograms.map((program) => (
              <tr 
                key={program.id} 
                className={`border-b cursor-pointer hover:bg-fuchsia-400 ${selectedProgram?.id === program.id ? 'bg-fuchsia-300' : ''}`}
                onClick={() => handleSelectProgram(program)}
              >
                <td className="p-2">
                  <div className="flex items-center space-x-2">
                    <Avatar className="h-8 w-8">
                      <AvatarFallback>{program.title.charAt(0)}</AvatarFallback>
                    </Avatar>
                    <span>{program.title}</span>
                  </div>
                </td>
                <td className="p-2">{program.description}</td>
                <td className="p-2">
                  {program.exerciseSets?.length || 0} sets
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Cards for smaller screens */}
        <div className="grid grid-cols-1 gap-4 md:hidden p-4">
          {filteredPrograms.map((program) => (
            <div 
              key={program.id} 
              className={`bg-white shadow rounded-lg p-4 cursor-pointer ${selectedProgram?.id === program.id ? 'ring-2 ring-blue-500' : ''}`}
              onClick={() => handleSelectProgram(program)}
            >
              <div className="flex items-center space-x-4 mb-4">
                <Avatar className="h-12 w-12">
                  <AvatarFallback>{program.title.charAt(0)}</AvatarFallback>
                </Avatar>
                <div>
                  <p className="font-semibold">{program.title}</p>
                  <p className="text-sm text-gray-500">{program.exerciseSets?.length || 0} sets</p>
                </div>
              </div>
              <p className="text-sm text-gray-600">{program.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Fixed footer */}
      <div className="fixed bottom-0 right-0 bg-white z-10 p-2 shadow-md lg:left-64 left-0">
        <div className="max-w-7xl mx-auto flex items-center justify-between px-4">
          <div className="flex items-center space-x-2">
            <Input
              type="text"
              placeholder="Search programs"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-64"
            />
            <Button 
              onClick={handleEditProgram}
              disabled={!selectedProgram}
              className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              title="Edit Program"
            >
              <Edit size={20} />
            </Button>
            <Button 
              onClick={handleDeleteProgram}
              disabled={!selectedProgram}
              className="bg-red-500 text-white p-2 rounded hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              title="Delete Program"
            >
              <Trash2 size={20} />
            </Button>
          </div>
          <p className="text-sm text-gray-600">
            {selectedProgram ? `Selected: ${selectedProgram.title}` : 'No program selected'}
          </p>
        </div>
      </div>

      {selectedProgram && (
        <EditProgramModal
          program={selectedProgram}
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSave={handleSaveProgram}
        />
      )}
    </div>
  );
};

export default ManagePrograms;