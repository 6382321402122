import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { debounce } from 'lodash';
import api from '../../services/api';
import { Video, ExerciseSet } from './interfaces';
import VideoModal from './VideoModal';
import SetModal from './SetModal';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { PlusCircle, Edit, Trash2, FolderPlus, Search } from 'lucide-react';

const ManageVideos: React.FC = () => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [editingVideo, setEditingVideo] = useState<Video | null>(null);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isSetModalOpen, setIsSetModalOpen] = useState(false);
  const [selectedVideoIds, setSelectedVideoIds] = useState<string[]>([]);
  const [newSet, setNewSet] = useState<ExerciseSet>({ title: '', description: '', videoIds: [] });

  const observer = useRef<IntersectionObserver | null>(null);
  const lastVideoElementRef = useCallback((node: HTMLDivElement | null) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  useEffect(() => {
    fetchVideos();
  }, [page, searchTerm]);

  const fetchVideos = async () => {
    setLoading(true);
    try {
      const response = await api.get<Video[]>('/api/videos', {
        params: { page, limit: 20, search: searchTerm }
      });
      if (page === 1) {
        setVideos(response.data);
      } else {
        setVideos(prevVideos => [...prevVideos, ...response.data]);
      }
      setHasMore(response.data.length === 20);
    } catch (error) {
      console.error('Error fetching videos:', error);
      setError('Failed to fetch videos. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = debounce((term: string) => {
    setSearchTerm(term);
    setPage(1);
    setVideos([]);
  }, 300);

  const handleAddVideo = () => {
    setEditingVideo(null);
    setIsVideoModalOpen(true);
  };

  const handleEditVideo = () => {
    if (selectedVideoIds.length !== 1) {
      setError('Please select exactly one video to edit');
      return;
    }
    const videoToEdit = videos.find(v => v.id === selectedVideoIds[0]);
    if (videoToEdit) {
      setEditingVideo(videoToEdit);
      setIsVideoModalOpen(true);
    }
  };

  const handleCloseVideoModal = () => {
    setIsVideoModalOpen(false);
    setEditingVideo(null);
  };

  const handleSaveVideo = async (videoData: Partial<Video>) => {
    try {
      if (editingVideo) {
        await api.put(`/api/videos/${editingVideo.id}`, videoData);
      } else {
        await api.post('/api/videos', videoData);
      }
      fetchVideos();
      handleCloseVideoModal();
    } catch (error: any) {
      console.error('Error saving video:', error);
      setError('Failed to save video. Please try again.');
    }
  };

  const handleDeleteVideo = async () => {
    if (selectedVideoIds.length !== 1) {
      setError('Please select exactly one video to delete');
      return;
    }
    if (window.confirm('Are you sure you want to delete this video?')) {
      try {
        await api.delete(`/api/videos/${selectedVideoIds[0]}`);
        fetchVideos();
        setSelectedVideoIds([]);
      } catch (error) {
        console.error('Error deleting video:', error);
        setError('Failed to delete video. Please try again.');
      }
    }
  };

  const handleToggleVideoSelection = (videoId: string) => {
    setSelectedVideoIds(prev => 
      prev.includes(videoId) ? prev.filter(id => id !== videoId) : [...prev, videoId]
    );
  };

  const handleCreateSet = () => {
    if (selectedVideoIds.length === 0) {
      setError('Please select at least one video to create an exercise set');
      return;
    }
    setNewSet({ title: '', description: '', videoIds: selectedVideoIds });
    setIsSetModalOpen(true);
  };

  const handleSaveSet = async () => {
    try {
      if (!newSet.title || newSet.title.trim() === '') {
        setError('Title is required for creating an exercise set');
        return;
      }
  
      const setData = {
        title: newSet.title.trim(),
        description: newSet.description ? newSet.description.trim() : '',
        videoIds: selectedVideoIds
      };
  
      await api.post('/api/exercise-sets', setData);
      setIsSetModalOpen(false);
      setSelectedVideoIds([]);
      setNewSet({ title: '', description: '', videoIds: [] });
      fetchVideos();
    } catch (error: any) {
      console.error('Error creating exercise set:', error);
      setError(error.response?.data?.message || 'Failed to create exercise set. Please try again.');
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-auto pb-16">
        <div className="space-y-2 p-4">
          {videos.map((video, index) => (
            <div
              key={video.id}
              ref={index === videos.length - 1 ? lastVideoElementRef : null}
              className="flex items-center border rounded p-2 hover:bg-gray-100 transition-colors"
            >
              <input
                type="checkbox"
                checked={selectedVideoIds.includes(video.id)}
                onChange={() => handleToggleVideoSelection(video.id)}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <img src={video.thumbnailUrl} alt={video.title} className="w-24 h-16 object-cover mx-4" />
              <div className="flex-grow">
                <h3 className="font-semibold">{video.title}</h3>
                <p className="text-xs text-gray-600">{video.exerciseSets?.join(', ') || 'No exercise sets'}</p>
              </div>
            </div>
          ))}
        </div>
        {loading && <p className="p-4">Loading...</p>}
        {error && <p className="text-red-500 mt-4 p-4">{error}</p>}
      </div>
  
      <div className="fixed bottom-0 right-0 bg-white z-10 p-2 shadow-md lg:left-64 left-0">
        <div className="max-w-7xl mx-auto flex items-center justify-between px-4">
          <div className="flex items-center space-x-2">
            <Button 
              onClick={handleAddVideo}
              className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition-colors"
              title="Add New Video"
            >
              <PlusCircle size={20} />
            </Button>
            <Button 
              onClick={handleEditVideo}
              className="bg-yellow-500 text-white p-2 rounded hover:bg-yellow-600 transition-colors"
              disabled={selectedVideoIds.length !== 1}
              title="Edit Video"
            >
              <Edit size={20} />
            </Button>
            <Button 
              onClick={handleDeleteVideo}
              className="bg-red-500 text-white p-2 rounded hover:bg-red-600 transition-colors"
              disabled={selectedVideoIds.length !== 1}
              title="Delete Video"
            >
              <Trash2 size={20} />
            </Button>
            <Button 
              onClick={handleCreateSet}
              className="bg-green-500 text-white p-2 rounded hover:bg-green-600 transition-colors"
              disabled={selectedVideoIds.length === 0}
              title="Create Exercise Set"
            >
              <FolderPlus size={20} />
            </Button>
            <div className="relative">
              <Input
                type="text"
                placeholder="Search videos..."
                className="pl-10 pr-4 py-2 border rounded"
                onChange={(e) => handleSearch(e.target.value)}
              />
              <Search size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
          </div>
          <p className="text-sm text-gray-600">
            {selectedVideoIds.length} video(s) selected
          </p>
        </div>
      </div>
  
      {isVideoModalOpen && (
        <VideoModal
          video={editingVideo}
          onSave={handleSaveVideo}
          onClose={handleCloseVideoModal}
        />
      )}
  
      {isSetModalOpen && (
        <SetModal
          set={newSet}
          onSave={handleSaveSet}
          onClose={() => setIsSetModalOpen(false)}
          onChange={(field, value) => setNewSet(prev => ({ ...prev, [field]: value }))}
        />
      )}
    </div>
  );
};

export default ManageVideos;