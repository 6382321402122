import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import api from '../services/api';

export interface ExerciseSet {
  id: number;
  title: string;
  orderInProgram: number;
}

export interface Program {
  id: number;
  title: string;
  description: string;
  instructor_id: number;
  exerciseSetIds: number[]; // Keep this for backward compatibility
  exerciseSets: ExerciseSet[]; // New field with more detailed information
  createdAt: string;
  updatedAt: string;
}

interface ProgramState {
  programs: Program[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

// Update this type to match exactly what we're sending from the frontend
export type CreateProgramPayload = {
  title: string;
  description: string;
  exerciseSetIds: number[];
};

const initialState: ProgramState = {
  programs: [],
  status: 'idle',
  error: null,
};

export const fetchPrograms = createAsyncThunk('programs/fetchPrograms', async () => {
  const response = await api.get('/api/programs');
  return response.data;
});


export const createProgram = createAsyncThunk(
  'programs/createProgram', 
  async (programData: CreateProgramPayload) => {
    const response = await api.post('/api/programs', programData);
    return response.data;
  }
);

export const updateProgram = createAsyncThunk(
  'programs/updateProgram',
  async (program: Program) => {
    const response = await api.put(`/api/programs/${program.id}`, program);
    return response.data;
  }
);

export const deleteProgram = createAsyncThunk('programs/deleteProgram', async (id: number) => {
  await api.delete(`/api/programs/${id}`);
  return id;
});

const programSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrograms.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPrograms.fulfilled, (state, action: PayloadAction<Program[]>) => {
        state.status = 'succeeded';
        state.programs = action.payload;
      })
      .addCase(fetchPrograms.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
      .addCase(createProgram.fulfilled, (state, action: PayloadAction<Program>) => {
        state.programs.push(action.payload);
      })
      .addCase(updateProgram.fulfilled, (state, action: PayloadAction<Program>) => {
        const index = state.programs.findIndex(program => program.id === action.payload.id);
        if (index !== -1) {
          state.programs[index] = action.payload;
        }
      })
      .addCase(deleteProgram.fulfilled, (state, action: PayloadAction<number>) => {
        state.programs = state.programs.filter(program => program.id !== action.payload);
      });
  },
});

export default programSlice.reducer;