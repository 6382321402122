import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate,useLocation } from 'react-router-dom';
import { logout } from '../../store/authSlice';

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const location = useLocation();

  const getPageTitle = () => {
    switch (location.pathname) {
      case '/':
        return 'Dashboard';
      case '/exercise-sets':
        return 'Sets of Exercises';
      case '/programs':
        return 'Programs';
      case '/videos':
        return 'Manage Videos';
      case '/users':
        return 'Manage Users';
      case '/subscriptions':
        return 'Subscriptions';
      default:
        return '';
      }
    };
  

    return (
      <header className="bg-white shadow-md">
        <div className="max-w-7xl mx-auto py-4 px-16 pl-16">
          <h1 className="text-2xl font-bold text-gray-900">{getPageTitle()}</h1>
        </div>
      </header>
    );
  };

export default Header;