import React from 'react';
import { ExerciseSet } from './interfaces';

interface SetModalProps {
  set: ExerciseSet;
  onSave: () => void;
  onClose: () => void;
  onChange: (field: keyof ExerciseSet, value: string) => void;
}

const SetModal: React.FC<SetModalProps> = ({ set, onSave, onClose, onChange }) => {
  const handleInputChange = (field: keyof ExerciseSet) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(field, e.target.value);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded shadow-md max-w-md w-full">
        <h2 className="text-lg font-semibold mb-4">Create Exercise Set</h2>
        <form onSubmit={(e) => { e.preventDefault(); onSave(); }}>
          <div className="mb-4">
            <label className="block mb-2">Title</label>
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={set.title}
              onChange={handleInputChange('title')}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Description</label>
            <textarea
              className="w-full p-2 border rounded"
              value={set.description}
              onChange={handleInputChange('description')}
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded mr-2 hover:bg-gray-600 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
            >
              Save Set
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SetModal;
