import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExerciseSets, deleteExerciseSet, updateExerciseSet, ExerciseSet } from '../../store/exerciseSetSlice';
import { createProgram, fetchPrograms, CreateProgramPayload } from '../../store/programSlice';
import { RootState, AppDispatch } from '../../store/store';
import CreateProgramModal from './CreateProgramModal';
import EditExerciseSetModal from './EditExerciseSetModal';
import { toast } from 'react-toastify';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Edit, Trash2, Plus, CheckSquare } from 'lucide-react';

const ManageExerciseSets: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { exerciseSets, status, error } = useSelector((state: RootState) => state.exerciseSets);
  
  const [selectedSetIds, setSelectedSetIds] = useState<number[]>([]);
  const [isCreateProgramModalOpen, setIsCreateProgramModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchExerciseSets());
      dispatch(fetchPrograms());
    }
  }, [status, dispatch]);

  const handleToggleSetSelection = (setId: number) => {
    setSelectedSetIds(prev => 
      prev.includes(setId) ? prev.filter(id => id !== setId) : [...prev, setId]
    );
  };

  const handleCreateProgram = () => {
    if (selectedSetIds.length === 0) {
      toast.error('Please select at least one exercise set to create a program');
      return;
    }
    setIsCreateProgramModalOpen(true);
  };

  const handleEditSet = () => {
    if (selectedSetIds.length !== 1) {
      toast.error('Please select exactly one exercise set to edit');
      return;
    }
    setIsEditModalOpen(true);
  };

  const handleDeleteSet = async () => {
    if (selectedSetIds.length !== 1) {
      toast.error('Please select exactly one exercise set to delete');
      return;
    }
    if (window.confirm('Are you sure you want to delete this exercise set?')) {
      try {
        const resultAction = await dispatch(deleteExerciseSet(selectedSetIds[0]));
        if (deleteExerciseSet.fulfilled.match(resultAction)) {
          toast.success('Exercise set deleted successfully');
          setSelectedSetIds([]);
        } else if (deleteExerciseSet.rejected.match(resultAction)) {
          const error = resultAction.payload;
          toast.error(typeof error === 'string' ? error : 'Failed to delete exercise set');
        }
      } catch (error) {
        toast.error('An unexpected error occurred while deleting the exercise set');
      }
    }
  };

  const handleSaveProgram = async (programData: Omit<CreateProgramPayload, 'exerciseSetIds'>) => {
    try {
      const newProgramData: CreateProgramPayload = {
        ...programData,
        exerciseSetIds: selectedSetIds
      };
      await dispatch(createProgram(newProgramData));
      setIsCreateProgramModalOpen(false);
      setSelectedSetIds([]);
      toast.success('Program created successfully');
    } catch (error) {
      toast.error('Failed to create program');
    }
  };

  const handleUpdateSet = async (updatedSet: ExerciseSet) => {
    try {
      const resultAction = await dispatch(updateExerciseSet(updatedSet));
      if (updateExerciseSet.fulfilled.match(resultAction)) {
        setIsEditModalOpen(false);
        toast.success('Exercise set updated successfully');
      } else if (updateExerciseSet.rejected.match(resultAction)) {
        const error = resultAction.payload;
        toast.error(typeof error === 'string' ? error : 'Failed to update exercise set');
      }
    } catch (error) {
      toast.error('An unexpected error occurred while updating the exercise set');
    }
  };

  const filteredExerciseSets = exerciseSets.filter(set =>
    set.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    set.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderPrograms = (programs: string[] | undefined) => {
    return programs && programs.length > 0 ? programs.join(', ') : 'None';
  };

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-auto pb-16">
        {/* Table for larger screens */}
        <table className="w-full hidden md:table">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 text-left w-5">Select</th>
              <th className="p-2 text-left">Title</th>
              <th className="p-2 text-left">Description</th>
              <th className="p-2 text-left">Programs</th>
              <th className="p-2 text-left">Videos</th>
            </tr>
          </thead>
          <tbody>
            {filteredExerciseSets.map((set) => (
              <tr key={set.id} className="border-b">
                <td className="p-2">
                  <input
                    type="checkbox"
                    checked={selectedSetIds.includes(set.id)}
                    onChange={() => handleToggleSetSelection(set.id)}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                </td>
                <td className="p-2">
                  <div className="flex items-center space-x-2">
                    <Avatar className="h-8 w-8">
                      <AvatarFallback>{set.title.charAt(0)}</AvatarFallback>
                    </Avatar>
                    <span>{set.title}</span>
                  </div>
                </td>
                <td className="p-2">{set.description}</td>
                <td className="p-2">{renderPrograms(set.programs)}</td>
                <td className="p-2">{set.videoIds?.length || 0}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Cards for smaller screens */}
        <div className="grid grid-cols-1 gap-4 md:hidden p-4">
          {filteredExerciseSets.map((set) => (
            <div key={set.id} className="bg-white shadow rounded-lg p-4">
              <div className="flex items-center space-x-4 mb-4">
                <input
                  type="checkbox"
                  checked={selectedSetIds.includes(set.id)}
                  onChange={() => handleToggleSetSelection(set.id)}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <Avatar className="h-12 w-12">
                  <AvatarFallback>{set.title.charAt(0)}</AvatarFallback>
                </Avatar>
                <div>
                  <p className="font-semibold">{set.title}</p>
                  <p className="text-sm text-gray-500">{renderPrograms(set.programs)}</p>
                </div>
              </div>
              <p className="text-sm text-gray-600 mb-2">{set.description}</p>
              <p className="text-sm text-gray-500">Videos: {set.videoIds?.length || 0}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Fixed footer */}
      <div className="fixed bottom-0 right-0 bg-white z-10 p-2 shadow-md lg:left-64 left-0">
        <div className="max-w-7xl mx-auto flex items-center justify-between px-4">
          <div className="flex items-center space-x-2">
            <Input
              type="text"
              placeholder="Search exercise sets"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-64"
            />
            <Button 
              onClick={handleCreateProgram}
              disabled={selectedSetIds.length === 0}
              className="bg-green-500 text-white p-2 rounded hover:bg-green-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              title="Create Program"
            >
              <Plus size={20} />
            </Button>
            <Button 
              onClick={handleEditSet}
              disabled={selectedSetIds.length !== 1}
              className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              title="Edit Exercise Set"
            >
              <Edit size={20} />
            </Button>
            <Button 
              onClick={handleDeleteSet}
              disabled={selectedSetIds.length !== 1}
              className="bg-red-500 text-white p-2 rounded hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              title="Delete Exercise Set"
            >
              <Trash2 size={20} />
            </Button>
          </div>
          <p className="text-sm text-gray-600">
            {selectedSetIds.length} set(s) selected
          </p>
        </div>
      </div>

      {isCreateProgramModalOpen && (
        <CreateProgramModal 
          onClose={() => setIsCreateProgramModalOpen(false)}
          onSave={handleSaveProgram}
          selectedSets={selectedSetIds}
        />
      )}
      {isEditModalOpen && selectedSetIds.length === 1 && (
        <EditExerciseSetModal
          exerciseSet={exerciseSets.find(set => set.id === selectedSetIds[0]) || { id: 0, title: '', description: '', programs: [], videoIds: [] }}
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSave={handleUpdateSet}
        />
      )}
    </div>
  );
};

export default ManageExerciseSets;