import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { format, parseISO, isAfter, isBefore, isToday } from 'date-fns'
import EditSubscriptionModal from './EditSubscriptionModal';
import { adminRequest } from '../../services/api';
import { useAppDispatch } from '../../store/hooks';
import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import { UserSubscription, Program, ExerciseSet } from '../users/interfaces';
import { Edit } from 'lucide-react';

interface SubscriptionWithUser extends UserSubscription {
  userId: string;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
  };
  programTitle: string;
  exerciseSets: ExerciseSet[];
}

const ManageSubscriptions: React.FC = () => {
  const [subscriptions, setSubscriptions] = useState<SubscriptionWithUser[]>([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState<SubscriptionWithUser[]>([]);
  const [selectedSubscription, setSelectedSubscription] = useState<SubscriptionWithUser | null>(null);
  const [programs, setPrograms] = useState<Program[]>([]);
  const [allExerciseSets, setAllExerciseSets] = useState<ExerciseSet[]>([]);
  const [filter, setFilter] = useState<'all' | 'active' | 'pending' | 'finished'>('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [subscriptionExerciseSets, setSubscriptionExerciseSets] = useState<ExerciseSet[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchSubscriptions();
    fetchPrograms();
    fetchAllExerciseSets();
  }, []);

  useEffect(() => {
    filterSubscriptions();
  }, [subscriptions, filter, searchTerm]);

  const fetchSubscriptions = async () => {
    try {
      const response = await adminRequest('get', '/subscriptions');
      setSubscriptions(response.data);
    } catch (err) {
      setError('Failed to fetch subscriptions');
    }
  };

  const fetchPrograms = async () => {
    try {
      const response = await adminRequest('get', '/programs');
      setPrograms(response.data);
    } catch (err) {
      setError('Failed to fetch programs');
    }
  };

  const fetchAllExerciseSets = async () => {
    try {
      const response = await adminRequest('get', '/exercise-sets');
      setAllExerciseSets(response.data);
    } catch (err) {
      setError('Failed to fetch exercise sets');
    }
  };

  const filterSubscriptions = () => {
    let filtered = subscriptions;

    if (filter !== 'all') {
      const today = new Date();
      filtered = filtered.filter(sub => {
        const startDate = parseISO(sub.startDate);
        const endDate = parseISO(sub.endDate);
        switch (filter) {
          case 'active':
            return isAfter(today, startDate) && isBefore(today, endDate);
          case 'pending':
            return isBefore(today, startDate);
          case 'finished':
            return isAfter(today, endDate) || isToday(endDate);
          default:
            return true;
        }
      });
    }

    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter(sub =>
        `${sub.user.firstName} ${sub.user.lastName}`.toLowerCase().includes(lowerSearchTerm) ||
        sub.user.email.toLowerCase().includes(lowerSearchTerm)
      );
    }

    setFilteredSubscriptions(filtered);
  };

  const handleSelectSubscription = (subscription: SubscriptionWithUser) => {
    setSelectedSubscription(subscription.id === selectedSubscription?.id ? null : subscription);
  };

  const handleEditSubscription = async () => {
    if (selectedSubscription) {
      await fetchSubscriptionExerciseSets(selectedSubscription.id);
      setIsEditModalOpen(true);
    }
  };

  const fetchSubscriptionExerciseSets = async (subscriptionId: string) => {
    try {
      const response = await adminRequest('get', `/subscriptions/${subscriptionId}/exercise-sets`);
      setSubscriptionExerciseSets(response.data);
    } catch (err) {
      setError('Failed to fetch subscription exercise sets');
    }
  };

  const handleSaveSubscription = async (updatedSubscription: SubscriptionWithUser, newProgram?: Program) => {
    try {
      const response = await adminRequest('put', `/subscriptions/${updatedSubscription.id}`, {
        updatedSubscription,
        newProgram
      });
      const updatedSubscriptionFromServer: UserSubscription = response.data;
      
      const mergedSubscription: SubscriptionWithUser = {
        ...updatedSubscription,
        ...updatedSubscriptionFromServer,
        userId: updatedSubscription.userId,
        user: updatedSubscription.user,
        programTitle: newProgram ? newProgram.title : updatedSubscription.programTitle,
        exerciseSets: updatedSubscription.exerciseSets
      };

      setSubscriptions(subscriptions.map(sub => 
        sub.id === mergedSubscription.id ? mergedSubscription : sub
      ));
      setIsEditModalOpen(false);
    } catch (err) {
      setError('Failed to update subscription');
    }
  };

  const getSubscriptionStatus = (subscription: SubscriptionWithUser) => {
    const today = new Date();
    const startDate = parseISO(subscription.startDate);
    const endDate = parseISO(subscription.endDate);
    if (isAfter(today, endDate)) return 'Finished';
    if (isBefore(today, startDate)) return 'Pending';
    return 'Active';
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-auto pb-16">
        {/* Table for larger screens */}
        <table className="w-full hidden md:table">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 text-left">User</th>
              <th className="p-2 text-left">Program</th>
              <th className="p-2 text-left">Start Date</th>
              <th className="p-2 text-left">End Date</th>
              <th className="p-2 text-left">Duration</th>
              <th className="p-2 text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredSubscriptions.map((subscription) => (
              <tr 
                key={subscription.id} 
                className={`border-b cursor-pointer hover:bg-fuchsia-400 ${selectedSubscription?.id === subscription.id ? 'bg-fuchsia-300' : ''}`}
                onClick={() => handleSelectSubscription(subscription)}
              >
                <td className="p-2">
                  <div className="flex items-center space-x-2">
                    <Avatar className="h-8 w-8">
                      <AvatarFallback>{`${subscription.user.firstName[0]}${subscription.user.lastName[0]}`}</AvatarFallback>
                    </Avatar>
                    <span>{`${subscription.user.firstName} ${subscription.user.lastName}`}</span>
                  </div>
                </td>
                <td className="p-2">{subscription.programTitle}</td>
                <td className="p-2">{format(parseISO(subscription.startDate), 'dd-MM-yyyy')}</td>
                <td className="p-2">{format(parseISO(subscription.endDate), 'dd-MM-yyyy')}</td>
                <td className="p-2">{subscription.durationWeeks} weeks</td>
                <td className="p-2">{getSubscriptionStatus(subscription)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Cards for smaller screens */}
        <div className="grid grid-cols-1 gap-4 md:hidden p-4">
          {filteredSubscriptions.map((subscription) => (
            <div 
              key={subscription.id} 
              className={`bg-white shadow rounded-lg p-4 cursor-pointer ${selectedSubscription?.id === subscription.id ? 'ring-2 ring-blue-500' : ''}`}
              onClick={() => handleSelectSubscription(subscription)}
            >
              <div className="flex items-center space-x-4 mb-4">
                <Avatar className="h-12 w-12">
                  <AvatarFallback>{`${subscription.user.firstName[0]}${subscription.user.lastName[0]}`}</AvatarFallback>
                </Avatar>
                <div>
                  <p className="font-semibold">{`${subscription.user.firstName} ${subscription.user.lastName}`}</p>
                  <p className="text-sm text-gray-500">{subscription.programTitle}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2 text-sm">
                <p><span className="font-semibold">Start:</span> {format(parseISO(subscription.startDate), 'dd-MM-yyyy')}</p>
                <p><span className="font-semibold">End:</span> {format(parseISO(subscription.endDate), 'dd-MM-yyyy')}</p>
                <p><span className="font-semibold">Duration:</span> {subscription.durationWeeks} weeks</p>
                <p><span className="font-semibold">Status:</span> {getSubscriptionStatus(subscription)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Fixed footer */}
      <div className="fixed bottom-0 right-0 bg-white z-10 p-2 shadow-md lg:left-64 left-0">
        <div className="max-w-7xl mx-auto flex items-center justify-between px-4">
          <div className="flex items-center space-x-2">
            <Select onValueChange={(value: any) => setFilter(value)}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Filter by status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All</SelectItem>
                <SelectItem value="active">Active</SelectItem>
                <SelectItem value="pending">Pending</SelectItem>
                <SelectItem value="finished">Finished</SelectItem>
              </SelectContent>
            </Select>
            <Input
              type="text"
              placeholder="Search by user name or email"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-64"
            />
            <Button 
              onClick={handleEditSubscription}
              disabled={!selectedSubscription || getSubscriptionStatus(selectedSubscription) === 'Finished'}
              className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              title="Edit Subscription"
            >
              <Edit size={20} />
            </Button>
          </div>
          <p className="text-sm text-gray-600">
            {selectedSubscription ? `Selected: ${selectedSubscription.user.firstName} ${selectedSubscription.user.lastName}` : 'No subscription selected'}
          </p>
        </div>
      </div>

      {selectedSubscription && (
        <EditSubscriptionModal
          subscription={selectedSubscription}
          programs={programs}
          allExerciseSets={allExerciseSets}
          subscriptionExerciseSets={subscriptionExerciseSets}
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSave={handleSaveSubscription}
        />
      )}
    </div>
  );
};

export default ManageSubscriptions;