import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  fetchHelpSupportMessages,
  sendHelpSupportReply,
  fetchUsers,
  markMessageAsRead,
  resolveHelpSupportThread,
} from '../../store/messageSlice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import UserList from './UserList';
import HelpSupportArea from './HelpSupportArea';
import { HelpSupportMessage } from '../../store/messageSlice';

const AdminHelpSupport: React.FC = () => {
  const dispatch = useAppDispatch();
  const { helpSupportMessages, users, isLoading, error } = useSelector((state: RootState) => state.adminMessages);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchHelpSupportMessages());
  }, [dispatch]);

  const usersWithMessageCounts = users.map(user => ({
    ...user,
    messageCount: helpSupportMessages.filter((m: HelpSupportMessage) => m.user_id === user.id).length,
    hasUnreadMessages: helpSupportMessages.some((m: HelpSupportMessage) => m.user_id === user.id && !m.read && m.sender_role === 'user')
  }));

  const handleSendHelpSupportReply = (messageId: number, reply: string) => {
    dispatch(sendHelpSupportReply({ messageId, reply }));
  };

  const handleMarkAsRead = (messageId: number) => {
    dispatch(markMessageAsRead({ messageId, messageType: 'help_support' }));
  };

  const handleMarkAsResolved = (threadId: number) => {
    if (threadId) {
      dispatch(resolveHelpSupportThread(threadId));
    } else {
      console.error('Invalid thread ID');
      // Optionally, show an error message to the admin
    }
  };

  const filteredMessages = selectedUserId
    ? helpSupportMessages.filter(m => m.user_id === selectedUserId)
    : [];
    

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="h-[calc(100vh-4rem)] flex p-2">
      <div className="w-1/4 overflow-hidden pr-2">
        <UserList 
          users={usersWithMessageCounts}
          selectedUserId={selectedUserId}
          onUserSelect={setSelectedUserId}
          messages={helpSupportMessages}
        />
      </div>
      <div className="w-3/4 overflow-hidden">
        <HelpSupportArea
          messages={filteredMessages}
          onSendReply={handleSendHelpSupportReply}
          onMarkAsRead={handleMarkAsRead}
          onMarkAsResolved={handleMarkAsResolved}
        />
      </div>
    </div>
  );
};

export default AdminHelpSupport;