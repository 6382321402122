import React from 'react';
import { UserSubscription, Program } from './interfaces';
import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"
import { format, parseISO, addDays } from 'date-fns'

interface SubscriptionDetailsModalProps {
  subscription: UserSubscription;
  program: Program;
  isOpen: boolean;
  onClose: () => void;
}

const SubscriptionDetailsModal: React.FC<SubscriptionDetailsModalProps> = ({ subscription, program, isOpen, onClose }) => {
  const formatDate = (dateString: string) => {
    const date = addDays(parseISO(dateString), 1);
    return format(date, 'dd MMM yyyy');
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Subscription Details</DialogTitle>
        </DialogHeader>
        <div className="py-4">
          <p><strong>Program:</strong> {program.title}</p>
          <p><strong>Start Date:</strong> {formatDate(subscription.startDate)}</p>
          <p><strong>End Date:</strong> {formatDate(subscription.endDate)}</p>
          <p><strong>Duration:</strong> {subscription.durationWeeks} weeks</p>
          <p><strong>Status:</strong> {subscription.status}</p>
        </div>
        <DialogFooter>
          <Button onClick={onClose}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionDetailsModal;