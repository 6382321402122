import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { ExerciseSet } from '../../store/exerciseSetSlice';

interface EditExerciseSetModalProps {
  exerciseSet: ExerciseSet;
  isOpen: boolean;
  onClose: () => void;
  onSave: (updatedSet: ExerciseSet) => void;
}

const EditExerciseSetModal: React.FC<EditExerciseSetModalProps> = ({ exerciseSet, isOpen, onClose, onSave }) => {
  const [editedSet, setEditedSet] = useState(exerciseSet);

  useEffect(() => {
    setEditedSet(exerciseSet);
  }, [exerciseSet]);

  const handleSave = () => {
    onSave(editedSet);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Exercise Set</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Input
            id="title"
            value={editedSet.title}
            onChange={(e) => setEditedSet({ ...editedSet, title: e.target.value })}
            placeholder="Exercise Set Title"
          />
          <Textarea
            id="description"
            value={editedSet.description}
            onChange={(e) => setEditedSet({ ...editedSet, description: e.target.value })}
            placeholder="Exercise Set Description"
          />
        </div>
        <DialogFooter>
          <Button onClick={onClose} variant="outline">Cancel</Button>
          <Button onClick={handleSave}>Save Changes</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditExerciseSetModal;