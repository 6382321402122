import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Message, markMessageAsRead, UserInstructorMessage, HelpSupportMessage } from '../../store/messageSlice';

interface MessageAreaProps {
    messages: Message[];
    onSendMessage: (message: string) => void;
    onMarkAsResolved?: (messageId: number) => void;
    onMarkAsRead: (messageId: number) => void;
    isHelpSupport?: boolean;
    selectedUserId: number | null;
}

const MessageArea: React.FC<MessageAreaProps> = ({ messages, onSendMessage, onMarkAsResolved, onMarkAsRead, isHelpSupport, selectedUserId }) => {
    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        messages.forEach(message => {
            if (!message.read) {
                dispatch(markMessageAsRead({
                    messageId: message.id,
                    messageType: isHelpSupport ? 'help_support' : 'instructor'
                }));
            }
        });
        scrollToBottom();
    }, [messages, isHelpSupport, dispatch]);

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            onSendMessage(newMessage);
            setNewMessage('');
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const subject = isHelpSupport && messages.length > 0 ? (messages[0] as HelpSupportMessage).subject : 'Messages';

    const filteredMessages = selectedUserId
        ? messages.filter(m => 
            m.user_id === selectedUserId || 
            ('instructor_id' in m && m.instructor_id === selectedUserId)
        )
        : [];

    return (
        <Card className="h-full flex flex-col">
            <CardHeader className="pb-2">
                <CardTitle>{subject}</CardTitle>
            </CardHeader>
            <CardContent className="flex-grow overflow-hidden p-0">
                <div className="h-full overflow-y-auto p-4 space-y-4">
                    {filteredMessages.map((message) => (
                        <div key={message.id} className={`flex ${message.sender_role === 'user' ? 'justify-start' : 'justify-end'}`}>
                            <div className={`max-w-[70%] p-2 rounded-lg ${
                                message.sender_role === 'user' 
                                    ? 'bg-gray-100 rounded-tl-none' 
                                    : 'bg-blue-100 rounded-tr-none mr-2'
                            }`}>
                                <p className="text-sm">
                                    <strong>{message.sender_role === 'user' ? message.user_username : 'You'}</strong>
                                </p>
                                <p className="text-sm">{message.message}</p>
                                <p className="text-xs text-gray-500 mt-1">{new Date(message.created_at).toLocaleString()}</p>
                                {isHelpSupport && (message as HelpSupportMessage).status && (
                                    <p className="text-xs text-gray-500 mt-1">Status: {(message as HelpSupportMessage).status}</p>
                                )}
                            </div>
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                </div>
            </CardContent>
            <CardFooter className="p-4 bg-gray-50">
                <div className="flex space-x-2 w-full">
                    <Input
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Type your message..."
                        onKeyDown={handleKeyDown}
                        className="flex-grow"
                    />
                    <Button onClick={handleSendMessage}>Send</Button>
                </div>
            </CardFooter>
        </Card>
    );
};

export default MessageArea;