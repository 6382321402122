import React, { useState, useEffect } from 'react';
import { User, UserRole } from './interfaces';
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Switch } from "@/components/ui/switch"
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog"
import { Checkbox } from "@/components/ui/checkbox"

interface EditUserModalProps {
  user: User;
  onSave: (user: User) => void;
  onClose: () => void;
  onDelete: (userId: string) => void;
}

const EditUserModal: React.FC<EditUserModalProps> = ({ user, onSave, onClose, onDelete }) => {
  const [editedUser, setEditedUser] = useState<User>(user);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  useEffect(() => {
    setEditedUser(user);
  }, [user]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedUser(prev => ({ ...prev, [name]: value }));
  };

  const handleRoleChange = (value: UserRole) => {
    setEditedUser(prev => ({ ...prev, role: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(editedUser);
  };

  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  };

  const renderAvatar = () => {
    switch (editedUser.avatar_type) {
      case 'picture':
        return editedUser.profile_picture ? (
          <AvatarImage src={editedUser.profile_picture} alt={`${editedUser.first_name} ${editedUser.last_name}`} />
        ) : (
          <AvatarFallback>{getInitials(editedUser.first_name, editedUser.last_name)}</AvatarFallback>
        );
      case 'icon':
        return <AvatarFallback>👤</AvatarFallback>;
      case 'initials':
      default:
        return <AvatarFallback>{getInitials(editedUser.first_name, editedUser.last_name)}</AvatarFallback>;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4">Edit User</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex flex-col md:flex-row gap-6">
            <div className="md:w-1/3 flex flex-col items-center">
              <Avatar className="h-32 w-32">
                {renderAvatar()}
              </Avatar>
              <Select onValueChange={(value) => setEditedUser(prev => ({ ...prev, avatar_type: value as 'picture' | 'icon' | 'initials' }))} value={editedUser.avatar_type}>
                <SelectTrigger className="w-full mt-2">
                  <SelectValue placeholder="Select avatar type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="picture">Picture</SelectItem>
                  <SelectItem value="icon">Icon</SelectItem>
                  <SelectItem value="initials">Initials</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="md:w-2/3 grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <Label htmlFor="first_name">First Name</Label>
                <Input id="first_name" name="first_name" value={editedUser.first_name} onChange={handleChange} />
              </div>
              <div>
                <Label htmlFor="last_name">Last Name</Label>
                <Input id="last_name" name="last_name" value={editedUser.last_name} onChange={handleChange} />
              </div>
              <div>
                <Label htmlFor="username">Username</Label>
                <Input id="username" name="username" value={editedUser.username} onChange={handleChange} />
              </div>
              <div>
                <Label htmlFor="email">Email</Label>
                <Input id="email" name="email" type="email" value={editedUser.email} onChange={handleChange} />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <Label htmlFor="role">Role</Label>
              <Select onValueChange={handleRoleChange} value={editedUser.role}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select a role" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="guest">Guest</SelectItem>
                  <SelectItem value="user">User</SelectItem>
                  <SelectItem value="instructor">Instructor</SelectItem>
                  <SelectItem value="admin">Admin</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="deleteUser"
              checked={deleteConfirmed}
              onCheckedChange={(checked) => setDeleteConfirmed(checked as boolean)}
            />
            <Label htmlFor="deleteUser">I want to delete this user</Label>
          </div>

          {editedUser.subscription && (
              <div>
                <Label>Subscription Status: {editedUser.subscription.status}</Label>
              </div>
            )}

          <div className="flex justify-end space-x-2">
            <Button type="button" onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button type="submit">Save Changes</Button>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button type="button" disabled={!deleteConfirmed} variant="destructive">
                  Delete User
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    This action cannot be undone. This will permanently delete the user account
                    and remove all associated data from our servers.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={() => onDelete(user.id)}>
                    Yes, delete user
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUserModal;