import React, { useState, useEffect } from 'react';
import { Program } from '../../store/programSlice';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

interface EditProgramModalProps {
  program: Program;
  isOpen: boolean;
  onClose: () => void;
  onSave: (updatedProgram: Program) => void;
}

const EditProgramModal: React.FC<EditProgramModalProps> = ({ program, isOpen, onClose, onSave }) => {
  const [editedProgram, setEditedProgram] = useState(program);

  useEffect(() => {
    setEditedProgram(program);
  }, [program]);

  const handleSave = () => {
    onSave(editedProgram);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Program</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Input
            id="title"
            value={editedProgram.title}
            onChange={(e) => setEditedProgram({ ...editedProgram, title: e.target.value })}
            placeholder="Program Title"
          />
          <Textarea
            id="description"
            value={editedProgram.description}
            onChange={(e) => setEditedProgram({ ...editedProgram, description: e.target.value })}
            placeholder="Program Description"
          />
        </div>
        <DialogFooter>
          <Button onClick={onClose} variant="outline">Cancel</Button>
          <Button onClick={handleSave}>Save Changes</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditProgramModal;