import React, { useState, useEffect } from 'react';
import { User, Program, UserSubscription } from './interfaces';
import UserList from './UserList';
import EditUserModal from './EditUserModal';
import AssignProgramModal from './AssignProgramModal';
import api from '../../services/api';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchCurrentUser } from '../../store/authSlice';
import { fetchUsers, setUsers } from '../../store/userSlice';
import { UnknownAction, ThunkDispatch } from '@reduxjs/toolkit';

// Utility function to parse dates in a user object
const parseUserDates = (user: any): User => ({
  ...user,
  created_at: new Date(user.created_at),
  updated_at: new Date(user.updated_at),
  subscription: user.subscription ? {
    ...user.subscription,
    start_date: new Date(user.subscription.start_date),
    end_date: new Date(user.subscription.end_date)
  } : undefined
});

const ManageUsers: React.FC = () => {
  const [programs, setPrograms] = useState<Program[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const dispatch: ThunkDispatch<any, null, UnknownAction> = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth.user);
  const { users, loading } = useAppSelector((state) => state.users);

  useEffect(() => {
    dispatch(fetchCurrentUser());
    dispatch(fetchUsers());
    fetchPrograms();
  }, [dispatch]);

  const fetchPrograms = async () => {
    try {
      const response = await api.get('/api/programs');
      //console.log('Programs API response:', response.data);
      const fetchedPrograms = response.data || [];
      //console.log('Fetched programs:', fetchedPrograms);
      setPrograms(Array.isArray(fetchedPrograms) ? fetchedPrograms : [fetchedPrograms]);
    } catch (err) {
      setError('Failed to fetch programs');
      console.error('Error fetching programs:', err);
    }
  };

  const handleEditUser = (user: User) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  const handleAssignProgram = (user: User) => {
    setSelectedUser(user);
    setIsAssignModalOpen(true);
  };

  const handleSaveUser = async (updatedUser: User) => {
    try {
      await api.put(`/api/users/${updatedUser.id}`, { user: updatedUser });
      dispatch(setUsers(users.map(user => user.id === updatedUser.id ? updatedUser : user)));
      setIsEditModalOpen(false);
    } catch (err) {
      setError('Failed to update user');
    }
  };

  const handleDeleteUser = async (userId: string) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await api.delete(`/api/users/${userId}`);
        dispatch(setUsers(users.filter(user => user.id !== userId)));
      } catch (err) {
        setError('Failed to delete user');
      }
    }
  };

  const handleAssignProgramSubmit = async (userId: string, programId: string, startDate: Date, durationWeeks: number) => {
    try {
      await api.post('/api/program-assignments/assign', {
        userId,
        programId,
        startDate,
        durationWeeks
      });
      await dispatch(fetchUsers());
      setIsAssignModalOpen(false);
    } catch (err) {
      setError('Failed to assign program');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  //console.log('currentUser: ', currentUser);
  return (
    <div className="flex flex-col h-screen">
      <div className="flex-grow overflow-hidden">
        <UserList 
          users={users} 
          onEditUser={handleEditUser}
          onAssignProgram={handleAssignProgram}
          programs={programs}
        />
      </div>
      {isEditModalOpen && selectedUser  && (
        <EditUserModal
          user={selectedUser}
          onSave={handleSaveUser}
          onClose={() => setIsEditModalOpen(false)}
          onDelete={handleDeleteUser}
        />
      )}
      {isAssignModalOpen && selectedUser && (
        <AssignProgramModal
          user={selectedUser}
          programs={programs}
          onAssign={handleAssignProgramSubmit}
          onClose={() => setIsAssignModalOpen(false)}
        />
      )}
    </div>
  );
};

export default ManageUsers;