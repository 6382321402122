import React, { useState } from 'react';
import { User, Program } from './interfaces';
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Edit, UserPlus, Info } from 'lucide-react';
import { format, differenceInDays, isValid, parseISO, addDays } from 'date-fns';
import SubscriptionDetailsModal from './SubscriptionDetailsModal';

interface UserListProps {
  users: User[];
  programs: Program[];
  onEditUser: (user: User) => void;
  onAssignProgram: (user: User) => void;
}

const UserList: React.FC<UserListProps> = ({ users, programs, onEditUser, onAssignProgram }) => {
  //console.log('Users in UserList:', users);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);

  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  };

  const handleSelectUser = (user: User) => {
    setSelectedUser(user.id === selectedUser?.id ? null : user);
  };

  const renderAvatar = (user: User) => {
    return (
      <Avatar className="h-8 w-8">
        {user.avatar_type === 'picture' && user.profile_picture ? (
          <AvatarImage src={user.profile_picture} alt={`${user.first_name} ${user.last_name}`} />
        ) : user.avatar_type === 'icon' ? (
          <AvatarFallback>👤</AvatarFallback>
        ) : (
          <AvatarFallback>{getInitials(user.first_name, user.last_name)}</AvatarFallback>
        )}
      </Avatar>
    );
  };

  const getSubscriptionEndDateColor = (endDate: string | undefined) => {
    if (!endDate) return 'bg-gray-100 text-gray-600';
    
    const dateObject = addDays(parseISO(endDate), 1);
    
    if (!isValid(dateObject)) return 'bg-gray-100 text-gray-600';
    
    const daysRemaining = differenceInDays(dateObject, new Date());
    
    if (daysRemaining > 14) return 'bg-white text-gray-600 border border-gray-300';
    if (daysRemaining > 7) return 'bg-orange-100 text-orange-600';
    return 'bg-orange-200 text-orange-700';
  };

  const renderSubscriptionEndDate = (user: User) => {
    if (!user.subscription?.endDate) return 'No active subscription';
    
    const endDate = addDays(parseISO(user.subscription.endDate), 1);

    if (!isValid(endDate)) {
      console.error(`Invalid end_date for user ${user.id}:`, user.subscription.endDate);
      return 'Invalid end date';
    }

    const formattedDate = format(endDate, 'dd-MMM-yyyy');
    const colorClass = getSubscriptionEndDateColor(user.subscription.endDate);

    return (
      <div className="flex items-center">
        <span className={`px-2 py-1 rounded-full ${colorClass} mr-2`}>
          {formattedDate}
        </span>
        <button 
          onClick={(e) => {
            e.stopPropagation();
            setSelectedUser(user);
            setIsSubscriptionModalOpen(true);
          }}
          className="text-blue-500 hover:text-blue-700"
        >
          <Info size={16} />
        </button>
      </div>
    );
  };


  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-auto pb-16">
        {/* Table for larger screens */}
        <table className="w-full hidden md:table">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 text-left">User</th>
              <th className="p-2 text-left">Email</th>
              <th className="p-2 text-left">Role</th>
              <th className="p-2 text-left">Subscription End</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr 
                key={user.id} 
                className={`border-b cursor-pointer hover:bg-fuchsia-400 ${selectedUser?.id === user.id ? 'bg-fuchsia-300' : ''}`}
                onClick={() => handleSelectUser(user)}
              >
                <td className="p-2">
                  <div className="flex items-center space-x-2">
                    {renderAvatar(user)}
                    <span>{user.first_name} {user.last_name}</span>
                  </div>
                </td>
                <td className="p-2">{user.email}</td>
                <td className="p-2">{user.role}</td>
                <td className="p-2">{renderSubscriptionEndDate(user)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Cards for smaller screens */}
        <div className="grid grid-cols-1 gap-4 md:hidden p-4">
          {users.map((user) => (
            <div 
              key={user.id} 
              className={`bg-white shadow rounded-lg p-4 cursor-pointer ${selectedUser?.id === user.id ? 'ring-2 ring-blue-500' : ''}`}
              onClick={() => handleSelectUser(user)}
            >
              <div className="flex items-center space-x-4 mb-4">
                {renderAvatar(user)}
                <div>
                  <p className="font-semibold">{user.first_name} {user.last_name}</p>
                  <p className="text-sm text-gray-500">{user.email}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <p className="text-sm"><span className="font-semibold">Role:</span> {user.role}</p>
                <p className="text-sm col-span-2">
                  <span className="font-semibold">Subscription End:</span> {renderSubscriptionEndDate(user)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Fixed footer */}
      <div className="fixed bottom-0 right-0 bg-white z-10 p-2 shadow-md lg:left-64 left-0"> {/* Updated left offset for larger screens */}
        <div className="max-w-7xl mx-auto flex items-center justify-between px-4">
          <div className="flex items-center space-x-2">
            <button 
              onClick={() => selectedUser && onEditUser(selectedUser)}
              className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!selectedUser}
              title="Edit User"
            >
              <Edit size={20} />
            </button>
            <button 
              onClick={() => selectedUser && onAssignProgram(selectedUser)}
              className="bg-green-500 text-white p-2 rounded hover:bg-green-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!selectedUser}
              title="Assign Program"
            >
              <UserPlus size={20} />
            </button>
          </div>
          <p className="text-sm text-gray-600">
            {selectedUser ? `Selected: ${selectedUser.first_name} ${selectedUser.last_name}` : 'No user selected'}
          </p>
        </div>
      </div>

      {selectedUser && selectedUser.subscription && (
        <SubscriptionDetailsModal
          subscription={selectedUser.subscription}
          program={programs.find(p => p.id === selectedUser.subscription?.programId) || programs[0]}
          isOpen={isSubscriptionModalOpen}
          onClose={() => setIsSubscriptionModalOpen(false)}
        />
      )}

    </div>
  );
};

export default UserList;