import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  fetchAdminMessages,
  sendAdminMessage,
  fetchUsers,
  markMessageAsRead,
  fetchUserMessages
} from '../../store/messageSlice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import UserList from './UserList';
import MessageArea from './MessageArea';
import { UserInstructorMessage } from '../../store/messageSlice';

const AdminMessages: React.FC = () => {
  const dispatch = useAppDispatch();
  const { userInstructorMessages, users, isLoading, error } = useSelector((state: RootState) => state.adminMessages);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

/*   const fetchMessages = useCallback(() => {
    dispatch(fetchAdminMessages());
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]); */

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchAdminMessages());
  }, [dispatch]);

  useEffect(() => {
    if (selectedUserId) {
      dispatch(fetchUserMessages(selectedUserId));
    }
  }, [selectedUserId, dispatch]);

  const usersWithMessageCounts = users.map(user => ({
    ...user,
    messageCount: userInstructorMessages.filter((m: UserInstructorMessage) => m.user_id === user.id).length,
    hasUnreadMessages: userInstructorMessages.some((m: UserInstructorMessage) => m.user_id === user.id && !m.read && m.sender_role === 'user')
  }));

  const handleSendMessage = (message: string) => {
    if (selectedUserId) {
      dispatch(sendAdminMessage({ userId: selectedUserId, message }));
    }
  };

  const handleMarkAsRead = (messageId: number) => {
    dispatch(markMessageAsRead({ messageId, messageType: 'instructor' }));
  };

  const filteredMessages = selectedUserId
    ? userInstructorMessages.filter(m => m.user_id === selectedUserId || m.instructor_id === selectedUserId)
    : [];

  // Sort messages by creation date
  const sortedMessages = [...filteredMessages].sort((a, b) => 
    new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="h-[calc(100vh-4rem)] flex p-2">
      <div className="w-1/4 overflow-hidden pr-2">
        <UserList 
          users={usersWithMessageCounts}
          selectedUserId={selectedUserId}
          onUserSelect={setSelectedUserId}
          messages={userInstructorMessages}
        />
      </div>
      <div className="w-3/4 overflow-hidden">
        <MessageArea
          messages={sortedMessages}
          onSendMessage={handleSendMessage}
          onMarkAsRead={handleMarkAsRead}
          selectedUserId={selectedUserId}
        />
      </div>
    </div>
  );
};

export default AdminMessages;