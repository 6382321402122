import React, { useState, useEffect } from 'react';
import { Video } from './interfaces';

interface VideoModalProps {
  video: Video | null;
  onSave: (videoData: Partial<Video>) => void;
  onClose: () => void;
}

const VideoModal: React.FC<VideoModalProps> = ({ video, onSave, onClose }) => {
  const [title, setTitle] = useState(video?.title || '');
  const [thumbnailUrl, setThumbnailUrl] = useState(video?.thumbnailUrl || '');

  useEffect(() => {
    setTitle(video?.title || '');
    setThumbnailUrl(video?.thumbnailUrl || '');
  }, [video]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave({ title, thumbnailUrl });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded shadow-md max-w-sm w-full">
        <h2 className="text-lg font-semibold mb-4">{video ? 'Edit Video' : 'Add Video'}</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2">Title</label>
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Thumbnail URL</label>
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={thumbnailUrl}
              onChange={(e) => setThumbnailUrl(e.target.value)}
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded mr-2 hover:bg-gray-600 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VideoModal;
