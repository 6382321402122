import React, { useState, useEffect } from 'react';
import { User, Program } from './interfaces';
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { addWeeks, startOfDay, endOfDay, format, parseISO } from "date-fns"
import { DateRange } from "react-day-picker"
import { CalendarIcon } from "lucide-react"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { cn } from "@/lib/utils"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"

interface AssignProgramModalProps {
  user: User;
  programs: Program[];
  onAssign: (userId: string, programId: string, startDate: Date, durationWeeks: number) => void;
  onClose: () => void;
}

const AssignProgramModal: React.FC<AssignProgramModalProps> = ({ user, programs, onAssign, onClose }) => {
  const [selectedProgram, setSelectedProgram] = useState<string>('');
  const [date, setDate] = useState<DateRange | undefined>({
    from: startOfDay(new Date()),
    to: endOfDay(addWeeks(new Date(), 1))
  });
  const [durationWeeks, setDurationWeeks] = useState<number>(1);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (user.subscription) {
      setShowWarning(true);
    }
  }, [user]);

  const updateDateRange = (newStart: Date, newDuration: number) => {
    setDate({
      from: startOfDay(newStart),
      to: endOfDay(addWeeks(newStart, newDuration))
    });
  };

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDuration = Math.max(1, parseInt(e.target.value) || 1);
    setDurationWeeks(newDuration);
    if (date?.from) {
      updateDateRange(date.from, newDuration);
    }
  };

  const handleDateChange = (newDate: DateRange | undefined) => {
    if (newDate?.from) {
      const newDuration = newDate.to
        ? Math.max(1, Math.round((newDate.to.getTime() - newDate.from.getTime()) / (7 * 24 * 60 * 60 * 1000)))
        : durationWeeks;
      setDate(newDate);
      setDurationWeeks(newDuration);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedProgram && date?.from) {
      onAssign(user.id, selectedProgram, date.from, durationWeeks);
    }
  };

  const isAssignButtonDisabled = () => {
    if (!selectedProgram || !date?.from || !date?.to) return true;
    if (showWarning && user.subscription?.endDate) {
      const endDate = parseISO(user.subscription.endDate);
      return endDate > new Date();
    }
    return false;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md max-h-[90vh] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4">Assign Program to {user.first_name} {user.last_name}</h2>
        
        {showWarning && (
          <Alert variant="destructive" className="mb-4">
            <AlertTitle>Warning</AlertTitle>
            <AlertDescription>
              This user already has an active subscription. Assigning a new program will override the existing one.
            </AlertDescription>
          </Alert>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <Label htmlFor="program">Program</Label>
            {programs && programs.length > 0 ? (
              <Select onValueChange={setSelectedProgram} value={selectedProgram}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select a program" />
                </SelectTrigger>
                <SelectContent>
                  {programs.map((program) => (
                    <SelectItem key={program.id} value={program.id.toString()}>{program.title}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            ) : (
              <p className="text-red-500">No programs available (Total programs: {programs ? programs.length : 0})</p>
            )}
          </div>
          
          <div className="grid gap-2">
            <Label>Date Range</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  id="date"
                  variant={"outline"}
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !date && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {date?.from ? (
                    date.to ? (
                      <>
                        {format(date.from, "LLL dd, y")} -{" "}
                        {format(date.to, "LLL dd, y")}
                      </>
                    ) : (
                      format(date.from, "LLL dd, y")
                    )
                  ) : (
                    <span>Pick a date</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  initialFocus
                  mode="range"
                  defaultMonth={date?.from}
                  selected={date}
                  onSelect={handleDateChange}
                  numberOfMonths={1}
                />
              </PopoverContent>
            </Popover>
          </div>

          <div>
            <Label htmlFor="duration">Program Duration (weeks)</Label>
            <Input
              id="duration"
              type="number"
              min="1"
              value={durationWeeks}
              onChange={handleDurationChange}
            />
          </div>
          
          <div className="flex justify-end space-x-2 mt-6">
            <Button type="button" onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button type="submit" disabled={isAssignButtonDisabled()}>
              Assign Program
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AssignProgramModal;