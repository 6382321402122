import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../services/api';
import { UserSubscription } from '../components/users/interfaces';

interface ProgramAssignmentError {
  message: string;
}

export const assignProgram = createAsyncThunk<
  { subscription: UserSubscription },
  { userId: string; programId: string; startDate: Date; durationWeeks: number },
  { rejectValue: ProgramAssignmentError }
>(
  'programAssignment/assign',
  async ({ userId, programId, startDate, durationWeeks }, thunkAPI) => {
    try {
      const response = await api.post('/program-assignments/assign', {
        userId,
        programId,
        startDate,
        durationWeeks
      });
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return thunkAPI.rejectWithValue({ message: error.message });
      }
      return thunkAPI.rejectWithValue({ message: 'An unknown error occurred' });
    }
  }
);

interface ProgramAssignmentState {
  loading: boolean;
  error: ProgramAssignmentError | null;
  currentAssignment: UserSubscription | null;
}

const initialState: ProgramAssignmentState = {
  loading: false,
  error: null,
  currentAssignment: null
};

const programAssignmentSlice = createSlice({
  name: 'programAssignment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(assignProgram.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(assignProgram.fulfilled, (state, action) => {
        state.loading = false;
        state.currentAssignment = action.payload.subscription;
      })
      .addCase(assignProgram.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? { message: 'An unknown error occurred' };
      });
  }
});

export default programAssignmentSlice.reducer;