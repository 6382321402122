import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { HelpSupportMessage } from '../../store/messageSlice';
import { cn } from "@/lib/utils";

interface HelpSupportAreaProps {
    messages: HelpSupportMessage[];
    onSendReply: (threadId: number, reply: string) => void;
    onMarkAsRead: (messageId: number) => void;
    onMarkAsResolved: (threadId: number) => void;
}

const HelpSupportArea: React.FC<HelpSupportAreaProps> = ({ messages, onSendReply, onMarkAsRead, onMarkAsResolved }) => {
    const [replyText, setReplyText] = useState('');
    const [selectedThreadId, setSelectedThreadId] = useState<number | null>(null);
    const [expandedThreads, setExpandedThreads] = useState<number[]>([]);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(scrollToBottom, [messages]);

    useEffect(() => {
        messages.forEach(message => {
            if (!message.read) {
                onMarkAsRead(message.id);
            }
        });
    }, [messages, onMarkAsRead]);

    const handleSendReply = () => {
        if (selectedThreadId && replyText.trim()) {
            onSendReply(selectedThreadId, replyText);
            setReplyText('');
        }
    };

    const toggleThreadExpansion = (threadId: number) => {
        setExpandedThreads(prev => 
            prev.includes(threadId) 
                ? prev.filter(id => id !== threadId)
                : [...prev, threadId]
        );
    };

    const groupedMessages = messages.reduce((acc, message) => {
        if (!acc[message.thread_id]) {
            acc[message.thread_id] = [];
        }
        acc[message.thread_id].push(message);
        return acc;
    }, {} as Record<number, HelpSupportMessage[]>);

    // Sort threads by the most recent message in each thread
    const sortedThreads = Object.entries(groupedMessages).sort(([, aMessages], [, bMessages]) => {
        const aLatest = new Date(aMessages[aMessages.length - 1].created_at).getTime();
        const bLatest = new Date(bMessages[bMessages.length - 1].created_at).getTime();
        return bLatest - aLatest;
    });

    return (
        <Card className="h-full flex flex-col">
            <CardHeader>
                <CardTitle>Help & Support Messages</CardTitle>
            </CardHeader>
            <CardContent className="flex-grow flex flex-col overflow-hidden">
                <div className="flex-grow overflow-y-auto mb-4 p-2">
                    {sortedThreads.map(([threadId, threadMessages]) => {
                        const isResolved = threadMessages.some(m => m.status === 'closed');
                        const isExpanded = expandedThreads.includes(Number(threadId));
                        const isSelected = selectedThreadId === parseInt(threadId, 10);
                        return (
                            <div 
                                key={threadId} 
                                className={cn(
                                    "mb-2 bg-gray-50 p-2 rounded-lg",
                                    isSelected && "ring-2 ring-blue-500"
                                )}
                            >
                                <div className="flex justify-between items-center">
                                    <h3 
                                        className="font-bold cursor-pointer"
                                        onClick={() => toggleThreadExpansion(Number(threadId))}
                                    >
                                        {threadMessages[0].thread_title} {isExpanded ? '▼' : '▶'}
                                    </h3>
                                    {!isResolved && (
                                        <Button 
                                            variant="outline" 
                                            size="sm"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onMarkAsResolved(parseInt(threadId, 10));
                                            }}
                                            className={cn(
                                                "transition-colors duration-200",
                                                isSelected ? "bg-blue-500 text-white hover:bg-green-500 mt-2 hover:text-white" : "hover:bg-gray-200"
                                            )}
                                        >
                                            Mark as Resolved
                                        </Button>
                                    )}
                                </div>
                                {isExpanded && (
                                    <>
                                        {threadMessages.map((message) => (
                                            <div key={message.id} className={`mt-2 p-3 rounded-md ${message.sender_role === 'user' ? 'bg-blue-100' : 'bg-green-100'}`}>
                                                <p className="text-sm font-semibold">{message.sender_role === 'user' ? message.user_username : 'Admin'}</p>
                                                <p className="mt-1">{message.message}</p>
                                                <p className="text-xs text-gray-500 mt-1">{new Date(message.created_at).toLocaleString()}</p>
                                            </div>
                                        ))}
                                        <Button 
                                            variant="outline"
                                            size="sm" 
                                            onClick={() => setSelectedThreadId(Number(threadId))}
                                            className={cn(
                                                "transition-colors duration-200",
                                                isSelected ? "bg-blue-500 text-white hover:bg-blue-700 mt-2 hover:text-white" : "hover:bg-gray-200 mt-2"
                                            )}
                                        >
                                            Reply to this thread
                                        </Button>
                                    </>
                                )}
                            </div>
                        );
                    })}
                    <div ref={messagesEndRef} />
                </div>
                {selectedThreadId && (
                    <div className="flex space-x-2">
                        <Input
                            value={replyText}
                            onChange={(e) => setReplyText(e.target.value)}
                            placeholder="Type your reply..."
                            onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && handleSendReply()}
                        />
                        <Button onClick={handleSendReply}>Send Reply</Button>
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

export default HelpSupportArea;